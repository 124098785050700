@import "./variables";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-multi-carousel-dot-list {
	// top: calc(61.8% + 0px) !important;
	// display: none !important;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.pageHeight {
	height: calc(100vh - 101px);
	overflow: scroll;
	// margin-top: 10px;
}
@include media-breakpoint-down(xl) {
	.pageHeight {
		height: unset;
	}
}

.helpPosition {
	width: 60%;
	margin-left: 20%;
	background-color: #F8F9FC;
}
.videoHeight {
	height: 450px;
}
.helpBackDrop {
	width: 100%;
	height: calc(100vh - 92px);
	background-color: #99999950;
	padding-top: 10%;
}
.helpContainer {
	width: 100%;
	height: 100%;
	position: absolute;
	pointer-events: none;
	z-index: 998;
}
@include media-breakpoint-down(xl) {
	.helpPosition {
		width: 90%;
		margin-left: 5%;
	}
	.videoHeight {
		height: 250px;
	}
	.helpBackDrop {
		position: fixed;
		height: calc(100% + 92px);
		pointer-events: none;
	}
	.helpContainer {
		margin-top: -92px;
	}
}

.financialForecastTitle {
	height: 0px;
	overflow: visible;
}
@include media-breakpoint-down(lg) {
	.financialForecastTitle {
		height: auto;
		padding-bottom: 0.5rem;
	}
}

.brand-color {
	color: #040529;
}

.soft-corners {
	border-radius: 8px !important;
}

.soft-glow {
	box-shadow: 1px 1px 10px 0px #C3CCF0;
}

.text-primary {
	color: $blue;
}

.colorBorder {
	border-color: #C3CCF0 !important;
}

.btn-primary {
	color: white !important;
	background-color: $blue !important;
	border-color: $blue;
	box-shadow: 2px 1px 4px 0px #00000040;
}
.btn-primary:hover {
	filter: brightness(85%);
	border-color: $blue;
}
.btn-secondary {
	color: #6B7B9C !important;
	background-color: $grey-title !important;
	border-color: $grey-title;
	box-shadow: 2px 1px 4px 0px #00000040;
}
.btn-secondary:hover {
	filter: brightness(85%);
	border-color: $grey-title;
}

.btn-info {
	color: $blue !important;
	background-color: $info !important;
	border-color: $info;
}
.btn-info:hover {
	filter: brightness(95%);
	border-color: $info;
}
.btn-info:active {
	border-color: $info !important;
}

.btn-outline-info {
	color: $info !important;
	background-color: $blue !important;
	border-color: $blue;
}
.btn-outline-info:hover {
	filter: brightness(95%);
	border-color: $blue;
}
.btn-outline-info:active {
	border-color: $blue !important;
}

.btn-outline-asset {
	color: $asset-0 !important;
	background-color: $white !important;
	border-color: $asset-0;
}
.btn-outline-asset:hover {
	filter: brightness(95%);
	border-color: $asset-0;
}
.btn-outline-asset:active {
	border-color: $asset-0 !important;
}
.btn-asset {
	color: $white !important;
	background-color: $asset-0 !important;
	border-color: $asset-0;
}
.btn-asset:hover {
	filter: brightness(85%);
	border-color: $asset-0;
}
.btn-asset:active {
	border-color: $asset-0 !important;
}

.btn-outline-saving {
	color: $positive-0 !important;
	background-color: $white !important;
	border-color: $positive-0;
}
.btn-outline-saving:hover {
	filter: brightness(95%);
	border-color: $positive-0;
}
.btn-outline-saving:active {
	border-color: $positive-0 !important;
}
.btn-saving {
	color: $white !important;
	background-color: $positive-0 !important;
	border-color: $positive-0;
}
.btn-saving:hover {
	filter: brightness(85%);
	border-color: $positive-0;
}
.btn-saving:active {
	border-color: $positive-0 !important;
}

.btn-outline-liability {
	color: $liability-0 !important;
	background-color: $white !important;
	border-color: $liability-0;
}
.btn-outline-liability:hover {
	filter: brightness(95%);
	border-color: $liability-0;
}
.btn-outline-liability:active {
	border-color: $liability-0 !important;
}
.btn-liability {
	color: $white !important;
	background-color: $liability-0 !important;
	border-color: $liability-0;
}
.btn-liability:hover {
	filter: brightness(85%);
	border-color: $liability-0;
}
.btn-liability:active {
	border-color: $liability-0 !important;
}

.btn-outline-selector {
	color: $blue !important;
	background-color: $white !important;
	border-color: $blue;
}
.btn-outline-selector:hover {
	filter: brightness(95%);
	border-color: $blue;
}
.btn-outline-selector:active {
	border-color: $blue !important;
}
.btn-selector {
	color: $white !important;
	background-color: $blue !important;
	border-color: $blue;
}
.btn-selector:hover {
	filter: brightness(85%);
	border-color: $blue;
}
.btn-selector:active {
	border-color: $blue !important;
}

.btn-warning {
	color: $liability-1 !important;
	border-color: $grey-title;
	background-color: white !important;
}
.btn-warning:hover {
	filter: brightness(95%);
	border-color: $grey-title;
}

.badgePositive {
	background-color: $positive-4 !important;
}
.badgeNegative {
	background-color: $negative-4 !important;
}

.bg-dark-gray {
	background-color: $grey-title;
}

.date-selector-arrow {
	height: 280px;
	width: 26px;
	position: relative;
	z-index: 100;
}

hr {
	border: none;
	height: 4px;
	color: #E5E5E5;
	background-color: #E5E5E5;
	opacity: 1;
}

hr.white {
	color: white;
	background-color: white;
	border: none;
	height: 1px;
	opacity: 1;
	width: 100%;
	margin-bottom: 0rem;
}

.login-logo {
	font-family: $font-logo;
	color: $brand-dark;
	text-align: center;
}
.login-logo .text {
	font-size: 70px;
	line-height: 70px;
	height: 70px;
}

.prift-logo-small {
	font-family: 'Audiowide', sans-serif;
	font-size: 24px;
	font-weight: 400;
	color: $gray-200;
	text-align: center;
}


// NAVIGATION
.nav-h {
	background-color: $brand-dark;
	color: $gray-200;
}
.nav-h-test {
	background-color: $brand-dark-test !important;
}
.nav-h-local {
	background-color: $brand-dark-local !important;
}

.nav-h-burger {
	color: $gray-200;
	font-size: 2rem;
}

.nav-v {
	background-color: $brand-dark;
	color: white;
}
.nav-v-test {
	background-color: $brand-dark-test !important;
}
.nav-v-local {
	background-color: $brand-dark-local !important;
}

.nav-v h4, .nav-v h5 {
	// font-weight: bolder;
	color: white;
}
.nav-v h4 {
	// font-size: 24px;
	font-weight: 500;
}
.nav-v h5 {
	// font-size: 20px;
	font-weight: 400;
}

.nav-v .navbar-nav .nav-link.active h4,
.nav-v .navbar-nav .nav-link.active h5 {
	font-weight: 700;
}

.nav-v .nav-contents {
	width: 100%;
}
.prift-logo-nav {
	font-family: $font-logo;
	font-size: 52px;
}
.prift-logo-nav .text {
	margin-left: 10px;
}
.prift-logo-nav .text .large {
	text-align: right;
	// padding-top: 1.4rem;
}
.prift-logo-nav .text .small {
	font-size: 12px;
	font-family: $font-family-sans-serif;
	white-space: nowrap;
	margin-top: -22px;
}
.offcanvas-title .prift-logo-nav {
	padding-top: 0.5rem !important;
}

.page-title {
	padding: 1.5rem 0;
	font-family: Inter;
	font-size: 36px;
	font-weight: 600;
	line-height: 43.57px;
	text-align: center;
}
.page-title-background {
	background-color: $grey-title;
}

.text-account-analysis-income {
	color: $asset-0;
	font-size: 20px;
	font-weight: 700;
	line-height: 24.2px;
	text-align: left;
}

.text-account-analysis-spending {
	color: $liability-0;
	font-size: 20px;
	font-weight: 700;
	line-height: 24.2px;
	text-align: left;
}

.date-tile-active {
	border-color: #003980 !important;
}

.bg-interaction {
	background-color: $body-interact !important;
}

.navigationTerms {
	font-family: Inter;
	font-size: 10px;
	font-weight: 400;
	line-height: 12.1px;
	text-align: left;
	color: $gray-200;

	// position: absolute;
	// bottom: 0;
	// width: 100%;
	// height: 330px;
}

.interaction {
	cursor: pointer;
}
.interaction.hasHoverEffect:hover {
	filter: brightness(98%);
}
.interaction.hasHoverEffect_large:hover {
	filter: brightness(80%)
}
.noDecoration {
	text-decoration: none !important;
}

.borderDashed {
	border-style: dashed !important;
}

.ssSavingBorder {
	border: 1px dashed #C4C4C4;
	border-left: 0px;
}

.hoverInteract {
	cursor: pointer;
}
.hoverInteract:hover {
	text-decoration: underline;
}

.rowHoverColor:hover > .hasBorder {
	border-bottom: 1px solid $gray-700 !important;
}
.rowHoverColor > .hasBorder {
	border-bottom: 1px solid transparent;
	min-height: 25px;
}

.text-muted {
	color: #6B7B9C !important;
}

.text-saving-color {
	color: $positive-0
}
.text-asset-color {
	color: $asset-0
}
.text-asset-color-1 {
	color: $asset-1
}

.text-spending-color {
	color: $liability-0
}
.text-spending-color-secondary {
	color: $liability-2
}
.text-positive-color {
	color: $positive-0
}
.text-positive-color-secondary {
	color: $positive-2
}
.text-negative-color {
	color: $negative-0
}

.svg-negative-color {
	fill: $negative-0
}
.svg-positive-color {
	fill: $positive-0
}
.svg-income-color {
	fill: $asset-0
}
.svg-saving-color {
	fill: $positive-0
}
.svg-spending-color {
	fill: $liability-0
}

.cashAccountTab {
	color: $asset-0 !important;
}
.cashAccountTab.active {
	color: white !important;
	background-color: $asset-0 !important;
}
.cardTab {
	color: $liability-0 !important;
}
.cardTab.active {
	color: white !important;
	background-color: $liability-0 !important;
}
.pensionTab {
	color: $positive-0 !important;
}
.pensionTab.active {
	color: white !important;
	background-color: $positive-0 !important;
}
.propertyTab {
	color: $asset-0 !important;
}
.propertyTab.active {
	color: white !important;
	background-color: $asset-0 !important;
}
.mortgageTab {
	color: $liability-0 !important;
}
.mortgageTab.active {
	color: white !important;
	background-color: $liability-0 !important;
}

.align-center {
	position: absolute;
	left: 50%;
	transform: translate(-50%,10rem);
}

.login-panel {
	width: 25rem;
	top: 0;
}

.login-error {
	text-align: center;
	color: $red;
}

.terms ol {
	counter-reset: section;
	list-style-type: none;
	padding-left: 1rem;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.terms li::before {
	counter-increment: section;
	content: counters(section, ".") " ";
}

.terms li {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.modal-60w {
	min-width: 60vw;
}
.modal-max-w-700 {
	max-width: 700px;
}

.modal-max-height {
	max-height: calc(100vh - 150px);
	overflow:scroll;
}

.sankeyNode {
	width: 100%;
	height: 100%;
}
.sankeyNode:hover {
	filter: brightness(85%);
}

.sankeyNode-income {
	background-color: $positive-0;
}

.sankeyNode-spending {
	background-color: $liability-0;
}

.sankeyNode-category {
	background-color: $negative-0;
}

.sankeyText-income {
	color: $positive-0;
}

.sankeyText-spending {
	color: $liability-0;
}

.sankeyText-category {
	color: $negative-0;
}