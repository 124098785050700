@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
// @import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$body-bg: #F8F9FC;
$brand-dark: #040529;
$brand-dark-test: #72061a;
$brand-dark-local: #0c7b08;

$body-fg: #6b7b9c;
$body-interact: #FAFBFD;

// Colors
$blue: #3377FF;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grey-title: #ededed;

$asset-0: #003980;
$asset-1: #0052f5;
$asset-2: #558eff;
$asset-3: #9bbaf9;
$asset-4: #b5d5ff;

$liability-0: #bb4f00;
$liability-1: #fc6a00;
$liability-2: #ff9d56;
$liability-3: #ffc194;
$liability-4: #ffe3ce;

$positive-0: #255555;
$positive-1: #3f9fa0;
$positive-2: #5abebb;
$positive-3: #afebe9;
$positive-4: #d9f5f5;
$positive-5: #eeffff;

$negative-0: #c81902;
$negative-1: #f31d00;
$negative-2: #ff6651;
$negative-3: #ffafa4;
$negative-4: #ffdad4;
$negative-5: #fff4f2;

$grey-0: #505D68;
$grey-1: #8E939C;
$grey-2: #C4C4C4;
$grey-3: #E5E7E9;

$primary: $blue;
$secondary: $grey-3;
$success: $green;
$info: #E8F2FF;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: crimson;

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1365px
// ) !default;


// Fonts
$font-family-sans-serif: "Inter", sans-serif;
$font-family-monospace: "Liberation Mono";
$font-logo: "Audiowide", sans-serif;

$font-size-root: null;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base * 0.85;

$font-sizes: (
  1: $h2-font-size,
  2: $h3-font-size,
  3: $h4-font-size,
  4: $h5-font-size,
  5: $h6-font-size,
  6: 0.75rem
);

$h2-font-weight: $font-weight-bolder;


@import "../../node_modules/bootstrap/scss/bootstrap.scss";